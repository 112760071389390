.leaflet-touch .leaflet-control-layers-toggle {
  width: 30px !important;
  height: 30px !important;
}

.table-bordered {
  border: none !important;
}

.tableCellMaintenanceSched {
  padding: 6px 5px 6px 5px !important;
}

.watermark {
  visibility: visible !important;
  background-image: url('/watermark.png') !important;
  background-repeat: no-repeat !important;
  /* background-attachment: fixed !important; */
  background-position: center !important;
  /* background-size: cover !important; */
  /* display: flex !important; */
  /* align-items: center !important; */
  /* width: 400px !important; */
  /* height: 400px !important; */
  -webkit-print-color-adjust: exact !important;
  padding: 40px !important;
}

@media print {
  .hiddenOverFlowPrint {
    height: 100% !important;
    overflow: auto !important;
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.menuitemtopbar {
  display: flex !important;
  font-size: 14px !important;
  font-weight: bold !important;
  /* padding-bottom: 0px !important; */
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
